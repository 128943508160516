import { Action } from 'typescript-fsa';

import ActionTypes from 'constants/ActionTypes/auth';
import { IUserType } from 'actions/users';

export type ILang = 'en' | 'es';

export interface IAuthState {
  lang: ILang;
  loading: boolean;
  error: string;
  access_token: string;
  refresh_token: string;
  usertype_id: IUserType;
  max_suscriptions: number;
  suscriptions_in_use: number;
  create_user_token: number;
  invalid_subscriptions_number: boolean;
  email: string;
  id:number;
}

const langStored = localStorage.getItem('nuv-mgmt-language');
const initialLanguage =
  langStored && (langStored === 'es' || langStored === 'en') ? langStored : 'es';

const initialState: IAuthState = {
  lang: initialLanguage,
  access_token: localStorage.getItem('nuv-mgmt-access-token') || '',
  refresh_token: localStorage.getItem('nuv-mgmt-refresh-token') || '',
  usertype_id: IUserType.white_label,
  loading: false,
  error: '',
  max_suscriptions: 0,
  suscriptions_in_use: 0,
  create_user_token: 0,
  invalid_subscriptions_number: false,
  email: '',
  id: 0,
};

export const auth = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.REQUEST_ACCESS_TOKEN:
    case ActionTypes.REQUEST_REFRESH_TOKEN: {
      const lang = state.lang;
      return {
        ...initialState,
        lang,
        loading: true,
      };
    }
    case ActionTypes.SUCCESS_ACCESS_TOKEN:
    case ActionTypes.SUCCESS_REFRESH_TOKEN: {
      localStorage.setItem('nuv-mgmt-access-token', action.payload.access_token);
      localStorage.setItem('nuv-mgmt-refresh-token', action.payload.refresh_token);
      return {
        ...state,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        usertype_id: IUserType.white_label,
        loading: false,
        error: '',
        max_suscriptions: 0,
        suscriptions_in_use: 0,
        create_user_token: 0,
      };
    }
    case ActionTypes.FAILURE_ACCESS_TOKEN: {
      localStorage.removeItem('nuv-mgmt-access-token');
      localStorage.removeItem('nuv-mgmt-refresh-token');
      return {
        ...state,
        access_token: '',
        refresh_token: '',
        usertype_id: IUserType.white_label,
        loading: false,
        error: 'login-error',
        max_suscriptions: 0,
        suscriptions_in_use: 0,
        create_user_token: 0,
      };
    }
    case ActionTypes.FAILURE_REFRESH_TOKEN: {
      return {
        ...state,
        access_token: '',
        refresh_token: '',
        usertype_id: IUserType.white_label,
        loading: false,
        error: action.payload.error,
        max_suscriptions: 0,
        suscriptions_in_use: 0,
        create_user_token: 0, 
      };
    }

    case ActionTypes.SUCCESS_PROFILE: {
      const usertype_id = action.payload.usertype_id;
      const max_suscriptions = action.payload.max_subscriptions || 0;
      const create_user_token = action.payload.create_user_token || 0;
      const suscriptions_in_use = action.payload.subscriptions_in_use || 0;
      const invalid_subscriptions_number = suscriptions_in_use > max_suscriptions;
      return {
        ...state,
        usertype_id,
        max_suscriptions,
        suscriptions_in_use,
        create_user_token,
        invalid_subscriptions_number,
        email: action.payload.email,
        id: action.payload.id,
      };
    }

    case ActionTypes.SUCCESS_LOGOUT: {
      localStorage.setItem('nuv-mgmt-access-token', '');
      localStorage.setItem('nuv-mgmt-refresh-token', '');
      return {
        ...initialState,
      };
    }

    case ActionTypes.SET_LANG: {
      localStorage.setItem('nuv-mgmt-language', action.payload);
      return {
        ...state,
        lang: action.payload,
      };
    }

    default:
      return state;
  }
};
