import React, { Component } from 'react';

import './Login.scss';
import Button from 'components/Button';
import { ILoginRequest } from 'actions/auth';
import { client_id, client_secret } from 'constants/auth';
import { FormattedMessage } from 'react-intl';
import { ILang } from 'reducers/auth';
import Dropdown, { IDropdownOption } from 'components/Dropdown/Dropdown';
import languages from 'lang/languages';
import { IntlFormatters } from 'react-intl';

interface IProps {
  intl: IntlFormatters;
  loading: boolean;
  error: string;
  lang: ILang;
  requestAccessToken: (loginData: ILoginRequest) => void;
  setLang: (val: ILang) => void;
}

interface ILoginState {
  email: string;
  password: string;
}

export class Login extends Component<IProps, ILoginState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLangChange = this.handleLangChange.bind(this);
  }

  handleEmailChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      email: e.currentTarget.value,
    });
  }

  handlePasswordChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      password: e.currentTarget.value,
    });
  }

  handleLangChange = (option: IDropdownOption) => {
    this.props.setLang(option.value);
  }

  handleSubmit(e: React.FormEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.props.requestAccessToken({
      grant_type: 'password',
      username: this.state.email,
      password: this.state.password,
      client_id,
      client_secret,
    });
  }

  render() {
    const error = this.props.error ? <p>{this.props.intl.formatMessage({ id: 'login-error' })}</p> : null;
    return (
      <section className='login'>
        <Dropdown
          label=''
          classes='language-login language-switcher'
          options={languages}
          selected={this.props.lang}
          onSelect={this.handleLangChange}
        />
        <div className='login-container'>
          <form onSubmit={this.handleSubmit}>
            <div className='form-element'>
              <FormattedMessage id='email'>
                {(txt) => <label htmlFor=''>{txt}</label>}
              </FormattedMessage>
              <input
                type='text'
                value={this.state.email}
                onChange={this.handleEmailChange}
                className='email'
              />
            </div>
            <div className='form-element'>
              <FormattedMessage id='password'>
                {(txt) => <label htmlFor=''>{txt}</label>}
              </FormattedMessage>
              <input
                type='password'
                value={this.state.password}
                onChange={this.handlePasswordChange}
                className='password'
              />
            </div>
            <div className='form-element'>
              <FormattedMessage id='login'>
                {(txt) => typeof txt === 'string' ? <Button bgColor='green' text={txt} isSubmit={true} /> : ''}
              </FormattedMessage>
            </div>
            {error}
          </form>
        </div>
      </section>
    );
  }
}

export default Login;
