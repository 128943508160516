const messages = {
  'email': 'Email',
  'password': 'Contraseña',
  'login': 'Ingresar',
  'logout': 'Cerrar sesión',
  'login-error': 'Datos de acceso incorrecto',

  'showStatus': 'Estado',
  'showClients': 'Clientes',
  'allUsers': 'Todos',
  'actives': 'Activos',
  'inactives': 'Inactivos',
  'allClients': 'Todos',
  'clients' : 'Clientes',
  'no-clients' : 'No clientes',
  'filter': 'Filtrar...',

  'col-status': 'E',
  'col-name': 'Nombre',
  'col-last-name': 'Apellido',
  'col-company': 'Empresa',
  'col-email': 'Email',
  'col-max-players': 'Máx.',
  'col-players-in-use': 'En uso',
  'col-last-login': 'Ultimo login',
  'col-expiration': 'Expiración',
  'col-type': 'Tipo',
  'col-client': 'Cliente',
  'col-empty': ' ',

  'music': 'Música',
  'music-active': 'Activada',
  'music-inactive': 'Desactivada',

  'client': 'Cliente',
  'client-active': 'Si',
  'client-inactive': 'No',

  'editing': 'Editando',
  'basic-info': 'Datos generales',
  'expiration-date': 'Fecha de expiración',
  'max-players': 'Máx. players',

  'status': 'Estado',
  'active': 'Activo',
  'inactive': 'Inactivo',

  'user-type': 'Tipo de usuario',
  'user-type-end': 'Usuario final',
  'user-type-root': 'Root',
  'user-type-white-label': 'Marca blanca',
  'user-type-partner': 'Partner',

  'music-clients': 'Musica para clientes',
  'white-label-config': 'Configuración de marca blanca / partner',

  'password-config': 'Contraseña',
  'new-password': 'Nueva contraseña',
  'repeat-new-password': 'Repetir nueva contraseña',
  'save': 'Guardar',

  'error-data-invalid': 'La información es inválida',
  'error-email-in-use': 'El email ya está en uso',
  'error-max-subscriptions': 'No puedes asignar más suscripciones que las que tienes permitidas',
  'error-general': 'Hubo un problema al intentar guardar la información',

  'password-admin': 'Contraseña Administrador',
  'new-password-admin': 'Nueva contraseña Administrador',
  'repeat-new-password-admin': 'Repetir nueva contraseña Administrador',

  'home' : 'Inicio',
  'users': 'Usuarios',
  'suscriptions' : 'Subscripciones',
  'new-user' : 'Nuevo Usuario',

  'open-account': 'Acceder a la cuenta',
  'close-panel': 'Cerrar panel',  
};

export default messages;
