import React from 'react';
import Dropdown from 'components/Dropdown';
import { IDropdownOption } from 'components/Dropdown/Dropdown';

import './Filter.scss';
import { IntlFormatters } from 'react-intl';
import { IUsersState } from 'reducers/users';
import { IUsersStatusFilter, IUsersClientFilter } from 'actions/users';


interface IProps {
  intl: IntlFormatters;
  usersState: IUsersState;
  filterUsersText: (val: string) => void;
  filterUsersStatus: (val: IUsersStatusFilter) => void;
  filterUsersClient: (val: IUsersClientFilter) => void;
  requestUsers: () => void;
}

const Filter = (props: IProps) => {

  const handleFilterText = (e: React.FormEvent<HTMLInputElement>) => {
    props.filterUsersText(e.currentTarget.value);
    props.requestUsers();
  };

  const handleFilterStatus = (option: IDropdownOption) => {
    if (
      option.value === IUsersStatusFilter.all ||
      option.value === IUsersStatusFilter.active ||
      option.value === IUsersStatusFilter.inactive
    ) {
      props.filterUsersStatus(option.value);
      props.requestUsers();
    }
  };

  const handleFilterClient = (option: IDropdownOption) => {
    if (
      option.value === IUsersStatusFilter.all ||
      option.value === IUsersClientFilter.client ||
      option.value === IUsersClientFilter.no_client
    ) {
      console.log(option.value);
      props.filterUsersClient(option.value);
      props.requestUsers();
    }
  };

  const { filter } = props.usersState;
  const statusOptions: IDropdownOption[] = [
    { value: '', label: props.intl.formatMessage({ id: 'allUsers' }) },
    { value: 1, label: props.intl.formatMessage({ id: 'actives' }) },
    { value: 0, label: props.intl.formatMessage({ id: 'inactives' }) },
  ];

  const clientsOptions: IDropdownOption[] = [
    { value: '', label: props.intl.formatMessage({ id: 'allClients' }) },
    { value: 1, label: props.intl.formatMessage({ id: 'clients' }) },
    { value: 0, label: props.intl.formatMessage({ id: 'no-clients' }) },
  ];

  return (
    <div className='Filter'>
      <header>
        <div className='filters'>
          <div className='filter-select'>
            <Dropdown
              label={props.intl.formatMessage({ id: 'showStatus' })}
              options={statusOptions}
              selected={filter.status}
              onSelect={handleFilterStatus}
            />
          </div>
          <div className='filter-select'>
            <Dropdown
              label={props.intl.formatMessage({ id: 'showClients' })}
              options={clientsOptions}
              selected={filter.client}
              onSelect={handleFilterClient}
            />
          </div>
          <input
            value={filter.text}
            onChange={handleFilterText}
            placeholder={props.intl.formatMessage({ id: 'filter' })}
          />
        </div>

      </header>
    </div>
  );
};

export default Filter;
