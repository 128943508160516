export const headers = [
  {
    name: 'active',
    value: 'E',
    sortable: true,
    id: 'col-status',
  },
  {
    name: 'first_name',
    value: 'Nombre',
    sortable: true,
    id: 'col-name',
  },
  {
    name: 'last_name',
    value: 'Apellido',
    sortable: true,
    id: 'col-last-name',
  },
  {
    value: 'Empresa',
    sortable: true,
    name: 'name',
    id: 'col-company',
  },
  {
    value: 'Email',
    sortable: true,
    name: 'email',
    id: 'col-email',
  },
  {
    value: 'Máx.',
    sortable: true,
    name: 'max_players',
    id: 'col-max-players',
  },
  {
    value: 'En uso',
    sortable: true,
    name: 'count_players',
    id: 'col-players-in-use',
  },
  {
    value: 'Ultimo login',
    sortable: true,
    name: 'last_login',
    id: 'col-last-login',
  },
  {
    value: 'Expiración',
    sortable: true,
    name: 'trial_ends_at',
    id: 'col-expiration',
  },
  {
    value: 'Cliente',
    sortable: true,
    name: 'client',
    id: 'col-client',
  },
  {
    value: '',
    sortable: false,
    name: '',
    id: 'col-empty',
  },
  
];


