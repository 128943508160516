import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as Routes from '../constants/routes';
import ActionTypes from '../constants/ActionTypes/routes';

export const goToHome = (action$: any) => action$.pipe(
  ofType(ActionTypes.GO_TO_HOME),
  mergeMap(() => {
    return of(push(Routes.home()));
  }),
);
