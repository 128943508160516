const messages = {
  'email': 'Email',
  'password': 'Password',
  'login': 'Login',
  'logout': 'Logout',
  'login-error': 'Login failed',

  'showStatus': 'State',
  'showClients': 'Clients',
  'allUsers': 'All',
  'actives': 'Actives',
  'inactives': 'Inactives',
  'allClients': 'All',
  'clients' : 'Clients',
  'no-clients' : 'Unclients',
  'filter': 'Filter...',

  'col-status': 'E',
  'col-name': 'Name',
  'col-last-name': 'Last name',
  'col-company': 'Company',
  'col-email': 'Email',
  'col-max-players': 'Máx.',
  'col-players-in-use': 'In use',
  'col-last-login': 'Last login',
  'col-expiration': 'Expiration',
  'col-type': 'Type',
  'col-client': 'Client',
  'col-empty': ' ',

  'music': 'Music',
  'music-active': 'Enabled',
  'music-inactive': 'Disabled',

  'client': 'Client',
  'client-active': 'Yes',
  'client-inactive': 'No',

  'editing': 'Editing',
  'basic-info': 'Basic info',
  'expiration-date': 'Expiration date',
  'max-players': 'Max. players',

  'status': 'Status',
  'active': 'Active',
  'inactive': 'Inactive',

  'user-type': 'User type',
  'user-type-end': 'End user',
  'user-type-root': 'Root',
  'user-type-white-label': 'White label',
  'user-type-partner': 'Partner',

  'music-clients': 'Music for clients',
  'white-label-config': 'White label/partner config',

  'password-config': 'Password',
  'new-password': 'New password',
  'repeat-new-password': 'Repeat new password',
  'save': 'Save',

  'error-data-invalid': 'The data is invalid.',
  'error-email-in-use': 'The email is already in use',
  'error-max-subscriptions': 'You can not assign more subscription that what you have available',
  'error-general': 'There was a problem at saving the data',

  'password-admin': 'Password Admin',
  'new-password-admin': 'New password Admin',
  'repeat-new-password-admin': 'Repeat new password Admin',

  'home' : 'Home',
  'users': 'Users',
  'suscriptions' : 'Suscriptions',
  'new-user' : 'New User',
  
  'open-account': 'Enter account',
  'close-panel': 'Close panel',  
};

export default messages;
