import React from 'react';
import UsersGrid from './UsersGrid';
import EditUser from 'containers/EditUser';
import { IUsersState } from 'reducers/users';
import { IUserList, IUserType } from 'actions/users';
import { IntlFormatters } from 'react-intl';
import Header from 'components/Header';
import Filter from './UsersGrid/Filter';
import './Users.scss';
import Button from 'components/Button';
import { ILang } from 'reducers/auth';
import { CMS_URL} from '../../constants/auth';

interface IProps {
  users: IUserList[];
  intl: IntlFormatters;
  loading: boolean;
  usersState: IUsersState;
  showEditUser: boolean;
  selectedId: number;
  loggedUserType: IUserType;
  showUser: (user: IUserList) => void;
  requestNewUser: () => void;
  user_id: number;
  create_user_token: number;
  lang: ILang,
}

const Users = (props: IProps) => {
  const {
    showEditUser,
  } = props;

  const editUser = showEditUser ? (
    <div className='edit-user-container'>
      <EditUser />
    </div>
  ) : null;
  
  const handleNewUser = () => {    
    const a = document.createElement('a');
    const cmsUrl = CMS_URL;
    
    a.href = cmsUrl+'/'+props.lang+'/signup/'+props.create_user_token+"/"+props.user_id;
    a.setAttribute('target', '_blank');
    a.click();
  };
  
    return (
      <React.Fragment>
        <Header />
        <section className='users-container'>
          <div className='users-list'>
            <div className='users-list-header'>        
              <Filter 
                intl = {props.intl}
                usersState = {props.usersState}
              />
              <Button
                text={props.intl.formatMessage({ id: 'new-user' })}
                click={handleNewUser}
                bgColor='green'
                extraClass='align-end'
              />
            </div>
            <UsersGrid 
              intl = {props.intl}
              showUser = {props.showUser}
            />
          </div>
          {editUser}
        </section>
      </React.Fragment>

     
    );
  
}

export default Users;