import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/poncho.min.css';
import 'font-awesome/css/font-awesome.min.css';

import './assets/scss/base.scss';

import Router from './router';
import { configureStore, history } from './store';


const renderApp = () => {
  const rootContainer = document.getElementById('root');
  const store = configureStore();
  const App = () => <Router history={history} />;
  const app = (
    <Provider store={store}>
      <App />
    </Provider>
  );

  render(app, rootContainer);
};

document.addEventListener('DOMContentLoaded', renderApp);
