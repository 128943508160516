import * as React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

import * as Routes from 'constants/routes';
import Login from 'containers/Login';
import Users from 'containers/Users';
import { IState } from 'reducers';

import { IntlProvider } from 'react-intl';
import messages from 'lang';
import { ILang } from 'reducers/auth';

interface IRouter {
  history: History;
  lang: ILang;
}

const Router = ({ history, lang }: IRouter ) => (
  <IntlProvider locale={lang} messages={messages[lang].default}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact={true} path={Routes.login()} component={Login} />
        <Route exact={true} path={Routes.home()} component={Users} />
      </Switch>
    </ConnectedRouter>
  </IntlProvider>
);

const mapStateToProps = (state: IState) => ({
  lang: state.data.auth.lang,
});

export default connect(mapStateToProps)(Router);
