export const client_id = '1';

// export const client_secret = 'yAtbJhHMgqtOP7FUoBpygZPySfbJQ5bUY3fQI3ts'; // local
// export const client_secret = '821FgKxqle34p8cKNhllnqwEKCNcq4dR1B8h5lbw'; // testing
export const client_secret = 'yAtbJhHMgqtOP7FUoBpygZPySfbJQ5bUY3fQI3ts'; // produccion

// export const BASE_URL: string = 'http://localhost/nuvelarmusicbackend/public/api'; // local
// export const BASE_URL: string = 'https://api.testing.nuvelar.com/public/api'; // testing
export const CMS_URL: string = 'https://www.nuvelar.com/public'; // local
//export const BASE_URL: string = 'http://127.0.0.1:8000/api'; // local
// export const BASE_URL: string = 'http://52.44.105.30/public/api'; // testing
export const BASE_URL: string = 'https://api.nuvelar.com/public/api'; // produccion

export const BASENAME: string = '/';
