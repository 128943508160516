import { connect } from 'react-redux';
import { flow } from 'lodash';

import Header from './Header';
import {
  requestLogout,
  requestProfile,
  setLang,
} from 'actions/auth';
import {
  filterUsersText,
  filterUsersStatus,
  filterUsersClient,
  requestUsers,
} from 'actions/users';
import { IState } from 'reducers';
import { injectIntl } from 'react-intl';

const mapStateToProps = (state: IState) => ({
  usersState: state.data.users,
  lang: state.data.auth.lang,
  max_suscriptions: state.data.auth.max_suscriptions,
  suscriptions_in_use: state.data.auth.suscriptions_in_use,
  create_user_token: state.data.auth.create_user_token,
  usertype_id: state.data.auth.usertype_id,
  invalid_subscriptions_number: state.data.auth.invalid_subscriptions_number,
  email: state.data.auth.email,
});

const mapDispatchToProps = {
  requestLogout,
  requestProfile,
  filterUsersText,
  filterUsersStatus,
  filterUsersClient,
  setLang,
  requestUsers,
};

const connectDecorator = connect(mapStateToProps, mapDispatchToProps);

export default flow([
  injectIntl,
  connectDecorator,
])(Header);
